// import React from 'react';
// import { TextField, Button, Box } from '@mui/material';

// function AddBillForm() {
//     return (
//         <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }}}>
//             <TextField label="Description" variant="outlined" />
//             <TextField label="Amount" type="number" variant="outlined" />
//             <Button variant="contained" color="primary" sx={{ mt: 2 }}>
//                 Add Bill
//             </Button>
//         </Box>
//     );
// }

// export default AddBillForm;
// AddBillForm.js
import React from 'react';
import { TextField, Button, Box } from '@mui/material';

function AddBillForm() {
    return (
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }}}>
            <TextField label="Description" variant="outlined" />
            <TextField label="Amount" type="number" variant="outlined" />
            <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                Add Bill
            </Button>
        </Box>
    );
}

export default AddBillForm;
