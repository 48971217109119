// import React, { useState } from 'react';
// import { TextField, Button, Box } from '@mui/material';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// function Login() {
//     const [credentials, setCredentials] = useState({ username: '', password: '' });
//     const navigate = useNavigate();

//     const handleLogin = async () => {
//         try {
//             const response = await axios.post('API_ENDPOINT/login', credentials);
//             console.log('Login Successful', response.data);
//             navigate('/');  // Redirect to the MainPage on successful login
//         } catch (error) {
//             console.error('Login Failed:', error.response);
//         }
//     };

//     return (
//         <Box component="form" onSubmit={handleLogin}>
//             <TextField label="Username" onChange={e => setCredentials({ ...credentials, username: e.target.value })} />
//             <TextField label="Password" type="password" onChange={e => setCredentials({ ...credentials, password: e.target.value })} />
//             <Button type="submit">Login</Button>
//         </Box>
//     );
// }

// export default Login;


import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';

function Login() {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent the form from causing a page reload
        try {
            const response = await axios.post('API_ENDPOINT/login', credentials);
            console.log('Login Successful', response.data);
            navigate('/');  // Redirect to the MainPage on successful login
        } catch (error) {
            console.error('Login Failed:', error.response);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={6} sx={{ mt: 8, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={e => setCredentials({ ...credentials, username: e.target.value })}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                    />
                    <Button
                        type="submit"
                        
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,backgroundColor: '#8B4513', '&:hover': { backgroundColor: '#A0522D' } }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default Login;
