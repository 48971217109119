
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DrawerAppBar from './components/DrawerAppBar';
import MuiCarousel from './components/MuiCarousel';
import WhatsAppButton from './components/WhatsAppButton';
import AboutUs from './components/AboutUs';
import Service from './components/Service';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard'; 
import AddBill from './components/AddBill';
import CreateBill from './components/CreateBill';


function App() {
  return (
    <Router>
      <div className="App">

        <DrawerAppBar />
        <WhatsAppButton />
        <Routes>
          <Route path="/" element={<HomeComponents />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Service />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/addbill" element={<AddBill />} />
          <Route path="/cbill" element={<CreateBill />} />
        </Routes>
      </div>
    </Router>
  );
}

// Home page component that contains all the components for the main page scroll
function HomeComponents() {
  return (
    <>
      <div id="carousel">
        <MuiCarousel />
      </div>
      <div id="about">
        <AboutUs />
      </div>
      <div id="services">
        <Service />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="contact">
        <ContactUs />
      </div>
      {/* <div id="login">
        <Login />
      </div> */}
    </>
  );
}

export default App;
