import React from 'react';
import Slider from 'react-slick';
import { Paper, Typography, Box } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function MuiCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const slidesData = [
    {
      label: 'LIONS CARGO',
      description: <h1 style={{ fontFamily: 'Abhaya Libre, serif', textAlign: 'center', marginTop: '1px', marginBottom: '2px' }}>ආයුබෝවන්!</h1>,
      imgPath: '/images/car1.jpg',
    },
    {
      label: 'LIONS CARGO',
      description: <h1 style={{ fontFamily: 'Abhaya Libre, serif', textAlign: 'center', marginTop: '0px', marginBottom: '2px' }}>දකුණු කොරියාවේ සිංහ නාදය</h1>,
      imgPath: '/images/car2.jpg',
    },
    {
      label: 'LIONS CARGO',
      description: <h1 style={{ fontFamily: 'Abhaya Libre, serif', textAlign: 'center', marginTop: '0px', marginBottom: '2px' }}>විශ්වාසනීය සේවය</h1>,
      imgPath: '/images/car3.jpg',
    },
  ];

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <Paper
            key={index}
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 400,
              backgroundImage: `url(${slide.imgPath})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '50%', 
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                color: 'white',
                padding: '10px',
              }}
            >
              <Typography variant="h6" sx={{ textAlign: 'center' }}>{slide.label}</Typography>
              <Typography variant="body2" component="div" sx={{ textAlign: 'center' }}>{slide.description}</Typography>
            </Box>
          </Paper>
        ))}
      </Slider>
    </Box>
  );
}

export default MuiCarousel;