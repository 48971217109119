import React from 'react';
import { Button } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Ensure you have @mui/icons-material installed

const WhatsAppButton = () => {
  const phoneNumber = '+94719004575'; // Replace YOUR_PHONE_NUMBER with your actual WhatsApp number

  const handleClick = () => {
    // WhatsApp chat link format
    const chatUrl = `https://wa.me/${phoneNumber}`;
    window.open(chatUrl, '_blank');
  };

  return (
    <Button
      variant="contained"
      color="success" // Material-UI's success color is a shade of green, similar to WhatsApp's theme
      startIcon={<WhatsAppIcon />}
      onClick={handleClick}
      sx={{ position: 'fixed', bottom: 20, right: 20 }} // Positioning the button at the bottom-right corner of the page
    >
      Chat on WhatsApp
    </Button>
  );
};

export default WhatsAppButton;
