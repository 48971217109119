
import React from 'react'
import {  Typography, Box} from '@mui/material';

function CreateBill() {
  return (
    <Box>
      <Typography>
      Admin Dashboard 
      </Typography>

      




    </Box>
    


  )
}

export default CreateBill