import React from 'react';
import { Grid, Box, TextField, Typography, Button, Paper } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

function ContactUs() {
  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', minHeight: '100vh', backgroundColor: '#581304', color: 'white' }}>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ p: 4 }}>
        <Typography variant="h5" component="h1" gutterBottom sx={{ color: 'white' }}>
            Contact Us
          </Typography>
          <Typography sx={{ mb: 4 }}>
            Feel free to give us a call or send us an email with your questions or comments
          </Typography>
          <TextField label="Name" variant="filled" fullWidth margin="normal" sx={{ backgroundColor: '#fff', borderRadius: '4px' }} />
          <TextField label="Email address" variant="filled" fullWidth margin="normal" sx={{ backgroundColor: '#fff', borderRadius: '4px' }} />
          <TextField label="Phone number" variant="filled" fullWidth margin="normal" sx={{ backgroundColor: '#fff', borderRadius: '4px' }} />
          <TextField label="Message" variant="filled" fullWidth multiline rows={4} margin="normal" sx={{ backgroundColor: '#fff', borderRadius: '4px' }} />
          <Button variant="contained" sx={{ mt: 2, backgroundColor: '#8B0000' }}>Submit</Button>
        </Grid>
        <Grid item xs={12} md={6} sx={{ p: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Korea Branch
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, color: 'white', backgroundColor: 'transparent', borderColor: 'grey' }}>
            <Typography><MailOutlineIcon /> prmodakaps@gmail.com</Typography>
            <Typography><PhoneIcon /> +8271-9004575</Typography>
            <Typography><LocationOnIcon /> Korea</Typography>
          </Paper>
          <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
            Sri Lanka Branch
          </Typography>
          <Paper variant="outlined" sx={{ p: 2, color: 'white', backgroundColor: 'transparent', borderColor: 'grey' }}>
            <Typography><MailOutlineIcon /> prmodakaps@gmail.com</Typography>
            <Typography><PhoneIcon /> +9471-9004575</Typography>
            <Typography><LocationOnIcon /> Sri Lanka</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactUs;
