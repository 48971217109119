import React from 'react';
import { Typography, Grid, Card, CardContent, CardMedia, Box } from '@mui/material';

const AboutUs = () => {
  // Example data for team members
  const teamMembers = [
    { name: 'Jane Doe', role: 'CEO', imageUrl: 'path_to_image', bio: 'Jane has over 20 years of experience...' },
    { name: 'John Smith', role: 'CTO', imageUrl: 'path_to_image', bio: 'John is a leading expert in...' },
    // Add more members as needed
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h5" component="h1" gutterBottom sx={{ color: 'brown' }}>
        About Us
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Welcome to our company. We are committed to delivering exceptional value to our clients and stakeholders...
      </Typography>
      {/* <Typography variant="h4" component="h2" sx={{ marginTop: 4, marginBottom: 2 }}>
        Meet Our Team
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={member.imageUrl}
                alt={member.name}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {member.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {member.role}
                </Typography>
                <Typography variant="body2">
                  {member.bio}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
};

export default AboutUs;
