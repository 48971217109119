// import React from 'react';
// import { CssBaseline, Drawer, AppBar, Toolbar, List, Typography, Divider, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';

// const drawerWidth = 240;

// function AdminDashboard() {
//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
//                 <Toolbar>
//                     <Typography variant="h6" noWrap component="div">
//                         Admin Dashboard
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Drawer
//                 variant="permanent"
//                 sx={{
//                     width: drawerWidth,
//                     flexShrink: 0,
//                     [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
//                 }}
//             >
//                 <Toolbar />
//                 <Divider />
//                 <List>
//                     {['Add Bills', 'View Bills'].map((text, index) => (
//                         <ListItem button key={text}>
//                             <ListItemIcon>
//                                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
//                             </ListItemIcon>
//                             <ListItemText primary={text} />
//                         </ListItem>
//                     ))}
//                 </List>
//             </Drawer>
//             <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
//                 <Toolbar />
//                 {/* Content goes here */}
//             </Box>
//         </Box>
//     );
// }

// export default AdminDashboard;


import React, { useState } from 'react';
import { CssBaseline, Drawer, AppBar, Toolbar, List, ListItem, ListItemText, Typography, Box } from '@mui/material';
import AddBillForm from './AddBillForm';
import ViewBillTable from './ViewBillTable';

const drawerWidth = 240;

function AdminDashboard() {
    const [selectedView, setSelectedView] = useState('addBill');

    const handleListItemClick = (view) => {
        setSelectedView(view);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
                <Toolbar>
                    <Typography variant="h6" noWrap>
                        Admin Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <List>
                    <ListItem button onClick={() => handleListItemClick('addBill')}>
                        <ListItemText primary="Add Bill" />
                    </ListItem>
                    <ListItem button onClick={() => handleListItemClick('viewBill')}>
                        <ListItemText primary="View Bill" />
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {selectedView === 'addBill' ? <AddBillForm /> : <ViewBillTable />}
            </Box>
        </Box>
    );
}

export default AdminDashboard;
