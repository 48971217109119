import React from 'react';
import { Typography, Grid, Card, CardContent, Box, CardMedia } from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import BuildIcon from '@mui/icons-material/Build';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

const services = [
  {
    icon: <LaptopMacIcon />,
    title: 'Web Development',
    description: 'We offer state-of-the-art web development services for any requirement.'
  },
  {
    icon: <BuildIcon />,
    title: 'Custom Solutions',
    description: 'Tailored solutions to meet your business needs.'
  },
  {
    icon: <ThumbUpIcon />,
    title: 'Quality Assurance',
    description: 'Our QA services ensure your product is bug-free and top quality.'
  }
];

function Service() {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
     <Typography variant="h5" component="h1" gutterBottom sx={{ color: 'brown' }}>
        Our Services
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
              <CardMedia>
                <Box sx={{ fontSize: 60 }}>{service.icon}</Box>
              </CardMedia>
              <CardContent>
                <Typography variant="h6" component="div">
                  {service.title}
                </Typography>
                <Typography variant="body1">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Service;
