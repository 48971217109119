import React from 'react';
import { Typography, Grid, Card, CardContent, Button, Box } from '@mui/material';

const pricingPlans = [
  {
    title: 'Basic',
    price: '$9.99',
    features: ['Feature 1', 'Feature 2', 'Feature 3'],
    buttonText: 'Sign up',
    buttonVariant: 'outlined'
  },
  {
    title: 'Standard',
    price: '$29.99',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4'],
    buttonText: 'Get started',
    buttonVariant: 'contained'
  },
  {
    title: 'Premium',
    price: '$49.99',
    features: ['Feature 1', 'Feature 2', 'Feature 3', 'Feature 4', 'Feature 5'],
    buttonText: 'Join now',
    buttonVariant: 'contained'
  }
];

function Pricing() {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
     <Typography variant="h5" component="h1" gutterBottom sx={{ color: 'brown' }}>
        Pricing Plans
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {pricingPlans.map((plan, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Card raised>
              <CardContent>
                <Typography variant="h5" component="h2" align="center">{plan.title}</Typography>
                <Typography variant="h4" align="center" color="primary">{plan.price}</Typography>
                <ul>
                  {plan.features.map((feature, idx) => (
                    <Typography key={idx} component="li" variant="body1">{feature}</Typography>
                  ))}
                </ul>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button variant={plan.buttonVariant} color="primary">
                    {plan.buttonText}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Pricing;
